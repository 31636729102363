.loan-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.loan-card {
    height: 300px;
    width: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    margin: 10px;
}

.loan-card a {
    display: block;
    height: 100%;
    width: 100%;
}

.loan-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.loan-card:hover {
    transform: scale(1.05);
}

.loan-card:hover .loan-card-image {
    transform: scale(1.1);
}

.loan-card a {
    text-decoration: none;
}
