.login-body{
	background-color: #282c34;
	min-height: 100vh;
	align-items: center;
	vertical-align: middle;
}

.login-container{
	padding-top: 15%;
	color: white;
	text-align: center;
	align-items: center;
}

.login-form{
	width: 100%;
	text-align: center;
	align-items: center;
	display: flex;
  	flex-direction: column;
}

.login-input{
	width: 100%;
}

.login-error{
	color: red;
	font-weight: bolder;
}