.leaderboard-table{
    width: 100%;
}

.leaderboard-row{
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 1;
}

.leaderboard-number{
    width: 10%;
    text-align: center;
}

.leaderboard-a{
    color:white;
    text-decoration: none;
}

.leaderboard-a:hover {
    color: green;
    text-decoration: underline;
}




.api-section {
    margin-bottom: 30px;
  }
  .api-section h2 {
    font-size: 1.2em;
  }
  .api-section ul {
    list-style-type: none;
    padding: 0;
  }
  .api-section ul li {
    margin-bottom: 5px;
  }