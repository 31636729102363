.account-body{
	background-color: #282c34;
	min-height: 100vh;
	align-items: center;
	vertical-align: middle;
}

.account-container{
	padding-top: 5%;
	color: white;
	text-align: center;
	align-items: center;
}

.account-form{
	width: 100%;
	text-align: center;
	align-items: center;
	display: flex;
  	flex-direction: column;
}

.account-card {
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.692); /* Shadow effect */
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-top: 5%;
}

.account-error{
	color: red;
	font-weight: bolder;
}

.account-username{
    font-size: x-large;
}

.account-label{
    font-size: x-large;
    font-weight:500;
}

.account-answer {
    font-size: larger;
}


.account-modal {
	display: none; /* Hidden by default */
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
  }

  .account-modal-content {
	background-color: #fff;
	margin: 15% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 300px;
	text-align: center;
  }

  .account-modal-buttons {
	margin-top: 20px;
  }

  .account-modal-buttons button {
	margin: 5px;
	padding: 10px 20px;
	cursor: pointer;
  }